import { Center, Image } from "@chakra-ui/react";
import React from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";

interface IconDisplayProps {
  image: any;
  onClick?: Function;
}

const IconDisplay: React.FC<IconDisplayProps> = (props) => {
  return (
    <Center
      display="flex"
      flexDirection="column"
      alignContent="center"
      width="7rem"
      height="7rem"
      backgroundColor={COLORS_PRIMARY.BACKGROUND_OPAQUE}
      borderRadius="10px"
      {...props}
    >
      <Image justifySelf="center" src={props.image} />
    </Center>
  );
};

export default IconDisplay;

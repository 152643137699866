import { Box, Center, Grid, HStack, Image } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import { navigate } from "gatsby";
import { OpaliteTextStandard, OpaliteTextTitle } from "../shared/opalite_text";
import InstagramUnVerified from "../../images/verify_socials_icons/verify_socials_icon_instagram_unverified.svg";
import InstagramVerified from "../../images/verify_socials_icons/verify_socials_icon_instagram_verified.svg";
import TikTokVerified from "../../images/verify_socials_icons/verify_socials_icon_tik_tok_verified.svg";
import TikTokUnVerified from "../../images/verify_socials_icons/verify_socials_icon_tik_tok_unverified.svg";
import YoutubeComingSoon from "../../images/verify_socials_icons/verify_socials_icon_youtube_coming_soon.svg";
import TwitterComingSoon from "../../images/verify_socials_icons/verify_socials_icon_twitter_coming_soon.svg";
import SoundcloudComingSoon from "../../images/verify_socials_icons/verify_socials_icon_soundcloud_coming_soon.svg";
import BEComingSoon from "../../images/verify_socials_icons/verify_socials_icon_BE_coming_soon.svg";
import ZComingSoon from "../../images/verify_socials_icons/verify_socials_icon_Z_coming_soon.svg";
import Notification from "../shared/notification";
import IconDisplay from "./icon_display";
import { OpaliteButtonStandard } from "../shared/opalite_buttons";
import { ApiContext } from "../Root";
import { TikTokController } from "../../services/tiktok";

interface VerifySocialsProps {}

const VerifySocials: React.FC<VerifySocialsProps> = () => {
  const api = useContext(ApiContext);
  const tikTokController = new TikTokController(api.client);
  const [verified, setVerified] = useState({ instagram: false, tikTok: false });
  const [toggleNotificationDisplay, setToggleNotificationDisplay] =
    useState(false);

  useEffect(() => {
    async function ue() {
      const accountData = await this.client.getAccount();
      if (!accountData.is_influencer) {
        navigate("/all_nft");
      }
      console.log("is influencer", accountData.is_influencer);
    }

    ue();
  });

  return (
    <Center
      padding="2rem"
      display="flex"
      flexDirection="column"
      height="100vh"
      backgroundColor={COLORS_PRIMARY.BACKGROUND}
    >
      <Notification
        visible={toggleNotificationDisplay}
        text="Socials verified"
        width="100%"
      />
      <OpaliteTextTitle marginTop="3rem" marginBottom="2rem" fontSize="2rem">
        Verify your socials
      </OpaliteTextTitle>
      <Grid
        marginBottom="2rem"
        width={{ base: "100%", md: "7rem" }}
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
        gap={2}
        justifyItems="center"
      >
        <IconDisplay
          onClick={() => {
            tikTokController.setupTikTok();
          }}
          image={verified.tikTok ? TikTokVerified : TikTokUnVerified}
        />
      </Grid>
      <OpaliteButtonStandard
        display={verified.instagram || verified.tikTok ? "flex" : "none"}
        width="100%"
      >
        I'm done!
      </OpaliteButtonStandard>
    </Center>
  );
};

export default VerifySocials;

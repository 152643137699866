import { navigate } from "gatsby";
import { ApiClient } from "./api";

export class TikTokController {
  client: ApiClient;

  constructor(api: ApiClient) {
    this.client = api;
  }

  async setupTikTok() {
    const tikTokUrlResponse = await this.client.getTikTokLoginUrl();
    if (!tikTokUrlResponse) {
      return;
    }
    if (typeof window !== "undefined") {
      window.localStorage.setItem("csrf_state", tikTokUrlResponse.csrf_state);
      window.open(tikTokUrlResponse.url, "_blank").focus();
    }
  }

  async createTikTokConnection() {
    if (typeof window !== "undefined") {
      const query = window.location.search;
      console.log("query", query);
      const code = new URLSearchParams(query).get("code");
      const state = new URLSearchParams(query).get("state");
      if (!code) {
        return;
      }

      const res = await this.client.createTikTokConnection({
        code,
        state,
        csrf_state: window.localStorage.getItem("csrf_state"),
      });
      if (!res.success) {
        // TODO error
        console.log("error creating tiktok connection");
        return;
      }
      navigate("/influencer_edit_profile");
    }
  }
}

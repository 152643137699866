import { Box } from "@chakra-ui/react";
import React from "react";
import Header from "../components/header/header";
import VerifySocials from "../components/verify_socials/verify_socials";

function VerifySocialsPage() {
  return (
    <Box>
      <Header />
      <VerifySocials />
    </Box>
  );
}

export default VerifySocialsPage;

import { HStack, Image } from "@chakra-ui/react";
import React, { useState } from "react";
import { COLORS_PRIMARY } from "./opalite_colors";
import { OpaliteTextStandard } from "./opalite_text";
import SmallCloseIconSVG from "../../images/small_close_icon.svg";

import GreenCheckmarkSVG from "../../images/green_checkmark.svg";

interface NotificationProps {
  text: string;
  width: string;
  visible: boolean;
}

const Notification: React.FC<NotificationProps> = (props) => {
  return (
    <HStack
      justifyContent="space-between"
      borderRadius="5px"
      padding=".5rem"
      backgroundColor={COLORS_PRIMARY.MINTING_IN_PROCESS_BACKGROUND}
      width={{ base: "auto", md: "30%" }}
      display={props.visible ? "flex" : "none"}
      width={props.width}
    >
      <HStack>
        <Image src={GreenCheckmarkSVG} />
        <OpaliteTextStandard color={COLORS_PRIMARY.BACKGROUND}>
          {props.text}
        </OpaliteTextStandard>
      </HStack>
      <Image
        onClick={() => setToggleMintingProgressDisplay(false)}
        src={SmallCloseIconSVG}
      />
    </HStack>
  );
};

export default Notification;
